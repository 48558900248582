<template>
  <el-dialog
    title="查看开锁码"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="440px"
  >
    <img v-if="qrCodeUrl" :src="qrCodeUrl" alt="开锁码" />
    <p class="open-code">
      {{ shops.openCode }}
    </p>
  </el-dialog>
</template>

<script>
export default {
  name: 'ShopscodeAddOrUpdate',
  data() {
    return {
      visible: false,
      shops: {
        id: '',
        name: '',
        openCode: '',
      },
    };
  },
  computed: {
    qrCodeUrl() {
      const openCode = this.shops.openCode;
      return openCode
        ? this.$http.BASE_URL + '/sys/generateQrCode/' + openCode
        : null;
    },
  },
  methods: {
    init(shopsId) {
      this.shops.id = shopsId || '';
      this.shops.openCode = '';
      this.visible = true;
      this.$nextTick(() => {
        this.$http({
          url: `/mall/shops/info/${this.shops.id}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.shops = data.shops;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.open-code {
  font-size: 18px;
  margin-left: 160px;
}
</style>
